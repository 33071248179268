import {DownloadedArtifacts, DownloadInfoWithBuild, stringifyId, toBuildId} from '../types'
import type {
  BuildId,
  BuildStats,
  BuildTriggeredType,
  BuildType,
  BuildTypeId,
  NormalizedBuildType,
  OperationResult,
  RequestOptionsParams,
  TagType,
} from '../types'
import {internalProps} from '../types/BS_types'
import type {RunCustomBuildStringParams} from '../types/BS_types'
import buildStats from '../utils/buildStats'
import {objectToQuery} from '../utils/queryParams'
import stableSort from '../utils/stableSort'

import {compatibleAgentsFields} from './buildCompatibleAgents'
import {buildTypeFields} from './buildTypes'
import processResponse from './processResponse'
import request from './request'
import type {RestRequestOptions} from './request'
import stream from './stream'

const CHANGES_LIMIT: number = internalProps['teamcity.internal.buildChangesPopupLimit']
const approvalWarming = 'approvalInfo(status),'
export const getBuildFields = (options?: RequestOptionsParams | null | undefined): string => {
  const withBuildTypesDetails = options?.withBuildTypeDetails
  let fields =
    'id,number,branchName,defaultBranch,queuedDate,startDate,finishDate,history,composite,' +
    'parallelized,' +
    'links(link(type,relativeUrl)),' +
    'comment(text,timestamp,user(id,name,username)),' +
    'statusChangeComment(text,timestamp,user(id,name,username)),' +
    'statusText,status,state,failedToStart,personal,detachedFromAgent,finishOnAgentDate,' +
    'pinned,pinInfo(text,timestamp,user(id,name,username)),' +
    'user(id,name,username),' +
    'customization,' +
    'canceledInfo(text,user(id,name,username)),' +
    `${approvalWarming}` +
    'agent(name,id,links(link(type,relativeUrl)),environment(osType),typeId,connected,pool(id,name)),' +
    'tags(tag(name,private),$locator(private:any,owner:current)),' +
    'artifacts($locator(count:1),count:($optional)),' +
    'limitedChangesCount($optional),' +
    `buildType(${
      withBuildTypesDetails === true
        ? buildTypeFields({
            withShortProjectDetails: options?.withShortProjectDetails,
            withLinks: true,
          }).join(',')
        : 'id'
    })`

  if (options?.withVirtualDependencies === true) {
    fields +=
      ',snapshot-dependencies(count,build(id,status,buildType(id,projectId,name,project(virtual))))'
  } else if (options?.withSnapshotDependencies === true) {
    fields += ',snapshot-dependencies(count,build(id))'
  } else {
    fields += ',snapshot-dependencies(count:(1))'
  }

  if (options?.withDownloadedArtifactsFrom != null) {
    fields += `,downloadedArtifacts($locator(id:${options.withDownloadedArtifactsFrom}),downloadInfo(artifactInfo(path)))`
  }

  if (options?.withRunningInfo === true) {
    // prettier-ignore
    fields +=
      ',running-info(' +
        'percentageComplete,' +
        'elapsedSeconds,' +
        'estimatedTotalSeconds,' +
        'leftSeconds,' +
        'probablyHanging,' +
        'lastActivityTime,' +
        'outdated,' +
        'outdatedReasonBuild(number,links(link(type,relativeUrl)))' +
      ')'
  }

  if (options?.withTestOccurrencesCount === true) {
    fields += ',testOccurrences(count)'
  }

  if (options?.withQueuedInfo === true) {
    // prettier-ignore
    fields +=
      ',waitReason,queuePosition,startEstimate,finishEstimate,' +
      'plannedAgent(name,id,environment(osType),typeId,pool(id,name)),' +
      'delayedByBuild(id,number,status,state,failedToStart,personal,canceledInfo,buildType(id)),' +
      'triggered(' +
        'date,' +
        'displayText,' +
        `buildType(${withBuildTypesDetails === true ? buildTypeFields({withLinks: true}).join(',') : 'id'})` +
      ')'
  }

  return fields
}
export default (
  serverUrl: string,
  locator: string = 'state:any',
  options?: RequestOptionsParams | null | undefined,
  onProgress: (build: BuildType) => void = () => {},
): Promise<ReadonlyArray<BuildType>> =>
  stream<{build: ReadonlyArray<BuildType>}, BuildType>(
    `${serverUrl}${options?.customEndpoint ?? '/builds'}?locator=${encodeURIComponent(
      locator,
    )}&fields=count,build(${getBuildFields(options)})`,
    '!.build[*]',
    onProgress,
    {
      essential: options?.essential,
    },
  ).then((data: {build: ReadonlyArray<BuildType>}) => data.build)
export const requestBuildsDetails = (
  serverUrl: string,
  locator: string = 'state:any',
  options: RequestOptionsParams | null | undefined,
  onProgress: (arg0: BuildType) => unknown = () => {},
): Promise<unknown> =>
  stream(
    `${serverUrl}${options?.customEndpoint ?? '/builds'}?locator=${encodeURIComponent(
      locator,
    )}&fields=build(${[
      'id',
      `changes($locator(count:${CHANGES_LIMIT}),change(id,username,commiter(users(user(id,name,username,avatars))))),artifactDependencyChanges(count)`,
      options?.withQueuedInfo === true ? compatibleAgentsFields : null,
    ]
      .filter(Boolean)
      .join(',')})`,
    '!.build[*]',
    onProgress,
  )
type BuildsCountResponse = {
  readonly count: number
  readonly nextHref?: string
}
export const requestBuildsCount = (
  serverUrl: string,
  locator: string,
  count: number | null,
  lookupLimit: number,
  options?: RequestOptionsParams | null | undefined,
): Promise<BuildsCountResponse> =>
  request(
    serverUrl,
    `${options?.customEndpoint?.replace(/^\//, '') ?? 'builds'}?locator=${encodeURIComponent(
      locator,
    )}${count != null ? `,count:${count}` : ''},lookupLimit:${lookupLimit}&fields=nextHref,count`,
    {
      essential: options?.essential,
    },
  ).then<BuildsCountResponse>(processResponse)

type BuildIdsBuild = {
  id: BuildId
}
type BuildIdsData = {
  build: BuildIdsBuild[]
}
export const requestBuildIds = (
  serverUrl: string,
  locator: string,
  options?: RequestOptionsParams | null | undefined,
): Promise<ReadonlyArray<BuildId>> =>
  request(
    serverUrl,
    `${options?.customEndpoint?.replace(/^\//, '') ?? 'builds'}?locator=${encodeURIComponent(
      locator,
    )},count:10000,lookupLimit:10000&fields=build(id)`,
  )
    .then<BuildIdsData>(processResponse)
    .then(data => data.build.map(build => build.id))

type HasBuildsData = {
  count: number
}
export const requestHasBuilds = (
  serverUrl: string,
  locator: string,
  restOptions?: RestRequestOptions | null | undefined,
): Promise<boolean> =>
  request(
    serverUrl,
    `builds?locator=${encodeURIComponent(locator)},count:1&fields=count`,
    restOptions,
  )
    .then<HasBuildsData>(processResponse)
    .then(data => data.count > 0)
export const requestSingleBuild = (
  serverUrl: string,
  locator: string,
  options?: RequestOptionsParams,
  restOptions?: RestRequestOptions,
): Promise<BuildType> =>
  request(
    serverUrl,
    `builds/${locator}?fields=${getBuildFields(options)}`,
    restOptions,
  ).then<BuildType>(processResponse)

type BuildTriggerBuildData = {
  triggered: BuildTriggeredType
}
export const requestBuildTriggerBuild = (
  serverUrl: string,
  buildId: BuildId,
): Promise<BuildTriggerBuildData> =>
  request(
    serverUrl,
    `builds/id:${stringifyId(
      buildId,
    )}?fields=triggered(build(id,number,links(link(type,relativeUrl))))`,
  ).then<BuildTriggerBuildData>(processResponse)

const getMultipleLocator = (buildIds: readonly BuildId[], withDependencies?: boolean) =>
  buildIds
    .map(
      id =>
        `item(id:${id})${
          withDependencies ? `,item(defaultFilter:false,snapshotDependency:(to:(id:${id})))` : ''
        }`,
    )
    .join(',')

export const saveBuildsComment = async (
  serverUrl: string,
  buildIds: readonly BuildId[],
  text: string,
): Promise<readonly OperationResult[]> => {
  const endpoint = `builds/multiple/${getMultipleLocator(
    buildIds,
  )}/comment?fields=operationResult(related(build(id,comment(text,timestamp,user(id,name,username)))))`
  const response = await request(serverUrl, endpoint, {
    method: 'PUT',
    body: text,
  })
  const {operationResult} = await processResponse(response)
  return operationResult
}

type ArtifactDependencyExistsData = {
  count: number
}
export const requestArtifactDependencyExists = (
  serverUrl: string,
  buildId: BuildId,
  toIfTrue: boolean,
): Promise<boolean> => {
  const endpoint = `builds?locator=defaultFilter:false,artifactDependency:(${
    toIfTrue ? 'to' : 'from'
  }:(id:${stringifyId(buildId)}),recursive:false)&fields=count`
  return request(serverUrl, endpoint, {
    method: 'GET',
  })
    .then<ArtifactDependencyExistsData>(processResponse)
    .then(data => data.count > 0)
}

const pin = (
  serverUrl: string,
  endpoint: string,
  status: boolean,
  commentText: string | null | undefined,
): Promise<Response> =>
  request(serverUrl, endpoint, {
    method: 'PUT',
    body: JSON.stringify({
      status,
      comment: {
        text: commentText,
      },
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })

export const pinBuilds = async (
  serverUrl: string,
  buildIds: readonly BuildId[],
  status: boolean,
  commentText: string | null | undefined,
  withDependencies: boolean,
): Promise<ReadonlyArray<OperationResult>> => {
  const response = await pin(
    serverUrl,
    `builds/multiple/${getMultipleLocator(
      buildIds,
      withDependencies,
    )}/pinInfo?fields=operationResult(related(build(id,pinned,pinInfo(text,timestamp,user(id,name,username)))))`,
    status,
    commentText,
  )
  const {operationResult} = await processResponse(response)
  return operationResult
}

export const changeTags = async (
  serverUrl: string,
  buildId: BuildId,
  tags: ReadonlyArray<TagType>,
): Promise<{
  tag: ReadonlyArray<TagType>
}> => {
  const endpoint = `builds/id:${stringifyId(buildId)}/tags?locator=private:any,owner:current`
  const response = await request(serverUrl, endpoint, {
    method: 'PUT',
    body: JSON.stringify({
      tag: tags,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return processResponse(response)
}
const addTagsByLocator = async (
  serverUrl: string,
  locator: string,
  tags: ReadonlyArray<TagType>,
): Promise<ReadonlyArray<OperationResult>> => {
  const endpoint = `builds/multiple/${locator}/tags?fields=operationResult(related(build(id,tags(tag(name,private),$locator(private:any,owner:current)))))`
  const response = await request(serverUrl, endpoint, {
    method: 'POST',
    body: JSON.stringify({
      tag: tags,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
  const {operationResult} = await processResponse(response)
  return operationResult
}
export const addTagsToDependencies = (
  serverUrl: string,
  buildId: BuildId,
  tags: ReadonlyArray<TagType>,
): Promise<ReadonlyArray<OperationResult>> =>
  addTagsByLocator(
    serverUrl,
    `defaultFilter:false,snapshotDependency:(to:(id:${stringifyId(buildId)}))`,
    tags,
  )
export const addTags = (
  serverUrl: string,
  buildIds: readonly BuildId[],
  tags: ReadonlyArray<TagType>,
  withDependencies: boolean,
): Promise<ReadonlyArray<OperationResult>> =>
  addTagsByLocator(serverUrl, getMultipleLocator(buildIds, withDependencies), tags)

export const moveToTop = async (serverUrl: string, buildId: BuildId): Promise<boolean> => {
  const endpoint = `buildQueue/order/1`
  const res = await request(serverUrl, endpoint, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: buildId,
    }),
  })
  return res.ok
}
export type RunBuildResult = {
  queuedBuildId: BuildId | null | undefined
  showDialog: boolean
}
export type TriggerBuildOptions = {
  readonly promoteId: BuildId | null | undefined
  readonly stateKey?: string
  readonly init?: boolean
  readonly initFromBuild?: BuildId
}
export const getPromoteOptions = (
  promoteId: BuildId | null | undefined,
): RunCustomBuildStringParams =>
  promoteId != null
    ? {
        dependOnPromotionIds: stringifyId(promoteId),
        init: 'true',
        stateKey: 'promote',
        redirectTo: '',
        modificationId: 'auto',
      }
    : Object.freeze({})
export const runBuild = async (
  buildTypeId: BuildTypeId,
  branchName: string | null | undefined,
  {promoteId}: TriggerBuildOptions,
): Promise<RunBuildResult> => {
  const res = await request(window.base_uri, 'ajax.html', {
    method: 'POST',
    headers: {
      Accept: 'text/xml',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    body: objectToQuery({
      add2Queue: stringifyId(buildTypeId),
      validate: 'true',
      branchName,
      ...getPromoteOptions(promoteId),
    }),
  })
  const xml = new DOMParser().parseFromString(await res.text(), 'text/xml')
  const queuedBuild = xml.querySelector('queuedBuild')
  const queuedBuildIdAttr = queuedBuild ? queuedBuild.getAttribute('itemId') : null
  const queuedBuildId = queuedBuildIdAttr != null ? toBuildId(queuedBuildIdAttr) : null
  return {
    queuedBuildId,
    showDialog: xml.getElementById('action_showRunCustomBuildDialog') != null,
  }
}
export const requestBuildsStats = (
  serverUrl: string,
  locator: string,
): Promise<ReadonlyArray<BuildStats>> =>
  request(
    serverUrl,
    `builds?locator=${encodeURIComponent(
      locator,
    )}&fields=build(id,queuedDate,startDate,finishDate,running-info(elapsedSeconds),status,state)`,
  )
    .then<{build: ReadonlyArray<NormalizedBuildType>}>(processResponse)
    .then(data => data.build.map(buildStats))
export const requestBuildsStatsAroundBuild = (
  serverUrl: string,
  locator: string,
  buildId: BuildId,
  statCount: number,
): Promise<ReadonlyArray<BuildStats>> =>
  requestBuildsStats(serverUrl, locator)
    .then((stats: ReadonlyArray<BuildStats>) =>
      stableSort(stats, (a, b) => {
        if (a.startDate == null || b.startDate == null || a.startDate === b.startDate) {
          return 0
        }

        return a.startDate < b.startDate ? 1 : -1
      }),
    )
    .then((stats: ReadonlyArray<BuildStats>) => {
      const wantedBuildIndex = stats.findIndex(stat => stat.id === buildId)
      const result = []

      if (stats.length > 0 && wantedBuildIndex !== -1) {
        result.push(stats[wantedBuildIndex])

        for (let i = 1; i < statCount; i++) {
          const previousElement = stats[wantedBuildIndex - i]
          const nextElement = stats[wantedBuildIndex + i]

          if (previousElement != null && result.length < statCount) {
            result.unshift(previousElement)
          }

          if (nextElement != null && result.length < statCount) {
            result.push(nextElement)
          }
        }
      }

      return result
    })
export const requestBuildCanBeRun = (serverUrl: string, buildId: BuildId): Promise<boolean> =>
  request(serverUrl, `?buildCanBeRun&buildId=${stringifyId(buildId)}`)
    .then<{readonly canRun: boolean}>(processResponse)
    .then(({canRun}) => canRun)

export const requestDownloadedArtifacts = (serverUrl: string, buildId: BuildId) =>
  request(
    serverUrl,
    `builds/id:${buildId}?fields=downloadedArtifacts(downloadInfo(build(id),artifactInfo(path)))`,
  )
    .then<DownloadedArtifacts<DownloadInfoWithBuild>>(processResponse)
    .then(({downloadedArtifacts}) =>
      Object.fromEntries(
        downloadedArtifacts.downloadInfo.map(({build, artifactInfo}) => [
          build.id,
          artifactInfo.map(item => item.path),
        ]),
      ),
    )
