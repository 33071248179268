import {createReducer} from '@reduxjs/toolkit'
import {castDraft} from 'immer'
import * as Redux from 'redux'

import type {Action} from '../../actions/types'
import fetchable, {keyValueFetchable} from '../../reducers/fetchable'
import {keyValueReducer} from '../../reducers/utils'
import {ChangeStatusType, toBuildId, toChangeId} from '../../types'
import type {BuildId, ChangeFileType, ChangeId, InexactCountable, RevisionId} from '../../types'
import {emptyArray} from '../../utils/empty'
import type {KeyValue} from '../../utils/object'

import {
  fetchBuildArtifactDependenciesChanges,
  fetchBuildChanges,
  fetchBuildChangesRevisions,
  fetchChanges,
} from './Changes.actions'
import {
  RECEIVE_CHANGES_FILES,
  ChangesStateType,
  REQUEST_CHANGE_BUILD_TYPES,
  RECEIVE_CHANGE_BUILD_TYPES,
  ChangeBuildTypeType,
  REQUEST_CHANGE_DEPLOYMENTS,
  RECEIVE_CHANGE_DEPLOYMENTS,
  ChangeDeploymentType,
} from './Changes.types'

const changeBuildTypesReducer = fetchable<
  readonly ChangeBuildTypeType[],
  readonly ChangeBuildTypeType[]
>(REQUEST_CHANGE_BUILD_TYPES, RECEIVE_CHANGE_BUILD_TYPES, emptyArray, action => action.data)

const changeDeploymentsReducer = fetchable<
  readonly ChangeDeploymentType[],
  readonly ChangeDeploymentType[]
>(REQUEST_CHANGE_DEPLOYMENTS, RECEIVE_CHANGE_DEPLOYMENTS, emptyArray, action => action.data)

const changesReducer = Redux.combineReducers<ChangesStateType>({
  changesByLocator: keyValueFetchable(
    arg => arg.locator,
    fetchChanges,
    emptyArray,
    (_, action) => action.payload.data.result,
  ),
  buildChangesRevisionsByLocator: keyValueFetchable(
    arg => arg.locator,
    fetchBuildChangesRevisions,
    emptyArray,
    (_, action) => action.payload.result,
  ),
  buildArtifactDependencyChangesByLocator: keyValueFetchable(
    arg => arg,
    fetchBuildArtifactDependenciesChanges,
    emptyArray,
    (_, action) => action.payload.result,
  ),
  changeBuildTypes: keyValueReducer(action => action.locator, changeBuildTypesReducer, [
    REQUEST_CHANGE_BUILD_TYPES,
    RECEIVE_CHANGE_BUILD_TYPES,
  ]),
  changeDeployments: keyValueReducer(action => action.locator, changeDeploymentsReducer, [
    REQUEST_CHANGE_DEPLOYMENTS,
    RECEIVE_CHANGE_DEPLOYMENTS,
  ]),

  changesFiles: createReducer<KeyValue<ChangeId, ReadonlyArray<ChangeFileType>>>({}, builder =>
    builder.addDefaultCase((state, action: Action) => {
      if (fetchChanges.fulfilled.match(action) && action.meta.arg.options?.withFiles) {
        const {changes} = action.payload.data.entities

        if (changes == null) {
          return
        }

        for (const [changeId, change] of Object.entries(changes)) {
          state[toChangeId(changeId)] = castDraft(change?.files?.file)
        }
      } else {
        switch (action.type) {
          case RECEIVE_CHANGES_FILES:
            if (!action.error) {
              const {changes} = action.data.entities

              if (changes == null) {
                return
              }

              for (const [changeId, change] of Object.entries(changes)) {
                state[toChangeId(changeId)] = castDraft(change?.files?.file)
              }
            }

            break

          default:
        }
      }
    }),
  ),

  changesStatuses: createReducer<KeyValue<ChangeId, ChangeStatusType>>({}, builder =>
    builder.addCase(fetchChanges.fulfilled, (state, action) => {
      if (action.meta.arg.options?.withStatus === true) {
        const {changes} = action.payload.data.entities

        if (changes == null) {
          return
        }

        for (const [changeId, change] of Object.entries(changes)) {
          state[toChangeId(changeId)] = castDraft(change?.status)
        }
      }
    }),
  ),

  buildChangesByBuildId: createReducer<KeyValue<BuildId, ReadonlyArray<ChangeId>>>({}, builder =>
    builder.addCase(fetchBuildChanges.fulfilled, (state, action) => {
      const {builds} = action.payload.entities

      if (builds == null) {
        return
      }

      for (const [buildId, build] of Object.entries(builds)) {
        state[toBuildId(buildId)] = castDraft(build?.changes?.change)
      }
    }),
  ),

  buildRevisions: createReducer<KeyValue<BuildId, ReadonlyArray<RevisionId>>>({}, builder =>
    builder.addCase(fetchBuildChangesRevisions.fulfilled, (state, action) => {
      const {builds} = action.payload.entities

      if (builds == null) {
        return
      }

      for (const [buildId, build] of Object.entries(builds)) {
        state[toBuildId(buildId)] = castDraft(build?.revisions?.revision ?? [])
      }
    }),
  ),

  buildSettingsRevisions: createReducer<KeyValue<BuildId, RevisionId | null | undefined>>(
    {},
    builder =>
      builder.addCase(fetchBuildChangesRevisions.fulfilled, (state, action) => {
        const {builds} = action.payload.entities

        if (builds == null) {
          return
        }

        for (const [buildId, build] of Object.entries(builds)) {
          state[toBuildId(buildId)] = build?.versionedSettingsRevision
        }
      }),
  ),

  artifactDependencyChangesCount: createReducer<KeyValue<BuildId, InexactCountable>>({}, builder =>
    builder.addCase(fetchBuildChanges.fulfilled, (state, action) => {
      const {builds} = action.payload.entities

      if (builds == null) {
        return
      }

      for (const [buildId, build] of Object.entries(builds)) {
        state[toBuildId(buildId)] = castDraft(build?.artifactDependencyChanges)
      }
    }),
  ),
})

export default changesReducer
